import styles from './Media.module.scss';

import Container from '@/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import dynamic from 'next/dynamic';
import typeMedia from '@/utilities/types/Media';
import typeSettings from '@/utilities/types/Settings';
import { Fragment } from 'react';

const Form = dynamic(() => import('@/components/molecules/Form'));
const Image = dynamic(() => import('next/image'));

interface MediaProps {
  id?: string;
  index: number;
  media: typeMedia[];
  settings: typeSettings;
}

const Media = (props: MediaProps) => {
  const { id, index, media, settings } = props;

  return (
    <div className={styles.root} id={id ? id : `row-${index + 1}`}>
      {media.map((media, index) => (
        <Fragment key={index}>
          {media.embed && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(media.embed, {
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: [
                    'frameborder',
                    'height',
                    'width',
                    'allow',
                    'allowfullscreen',
                    'webkitallowfullscreen',
                    'mozallowfullscreen',
                    'oallowfullscreen',
                    'msallowfullscreen',
                  ],
                }),
              }}
            />
          )}
          {media.form && media.customise && (
            <Container>
              <Form
                customise={media.customise}
                form={media.form}
                settings={settings}
              />
            </Container>
          )}
          {media.image && media.image.height && (
            <div
              className={styles.image}
              style={{
                ['--image-desktop-height' as string]: `${media.image.height}px`,
                ['--image-mobile-height' as string]: `${
                  media.image.height / 2
                }px`,
              }}
            >
              <Image
                alt={media.image.image.altText}
                height={media.image.image.mediaDetails.height || 520}
                src={media.image.image.mediaItemUrl}
                width={media.image.image.mediaDetails.width || 2246}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Media;
